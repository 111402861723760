<template lang="pug">
  v-container(fluid)
    v-data-table(
      :headers="filterHeaders"
      :items="duckEggCollectionIndexList"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :server-items-length="duckEggCollectionIndexGetCount"
      :loading="duckEggCollectionIndexGetting"
      dense
    )
      template(v-slot:item.revenue_source_id="{ item }")
        v-icon(
          color="yellow darken-3"
          @click="$emit('edit', item)"
        ) mdi-pencil
        span {{ item.revenue_source.code }}
      template(v-slot:item.date="{ value }")
        span {{ value.dateFormat() }}
      template(v-slot:item.order_id="{ item }")
        v-btn(
          v-if="item.invoice"
          link
          text
          color="primary"
          small
          block
          :to="`/orders/${item.order_id}/show`"
        ) {{ item.invoice.dr_number }}
        v-btn(
          v-else
          text
          color="blue-grey"
          small
          block
          @click="generateDr(item.id)"
          :loading="generateDrPosting"
        ) Generate DR
</template>
<script>
import duckEggCollectionRepository from '@/repositories/duck-egg-collection.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [duckEggCollectionIndexVars, duckEggCollectionIndexVarNames] = requestVars({ identifier: 'duck-egg-collection-index', pagination: true })
const [generateDRVars, generateDRVarNames] = requestVars({ identifier: 'generate-dr', request: 'post', hasData: false })

const duckEggCollectionIndexHandler = new VueRequestHandler(null, duckEggCollectionIndexVarNames, {}, true)
const generateDRHandler = new VueRequestHandler(null, generateDRVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Revenue Source', value: 'revenue_source_id', sortable: false },
    { text: 'Good', value: 'good', sortable: false },
    { text: 'Reject', value: 'reject', sortable: false },
    { text: 'Cracked', value: 'cracked', sortable: false },
    { text: 'Date', value: 'date', sortable: false },
    { text: 'DR Number', value: 'order_id', sortable: false },
  ],
  page: 1,
  itemsPerPage: 10,
})

export default {
  name: 'TableDuckEggCollection',
  data () {
    return {
      ...tableVars(),
      ...duckEggCollectionIndexVars,
      ...generateDRVars,
    }
  },
  computed: {
    tableParams () {
      return {
        page: this.page,
        limit: this.itemsPerPage,
      }
    },
    accessLevel () {
      return this.$store.state.auth.accessLevel
    },
    filterHeaders () {
      return this.headers.filter(item => {
        if (item.value === 'order_id') return this.accessLevel >= 6
        return true
      })
    },
  },
  created () {
    this.getDuckEggCollectionIndex()
    this.initWebsocket()
  },
  watch: {
    tableParams () {
      this.getDuckEggCollectionIndex()
    },
  },
  methods: {
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      const dbStoreEvent = ({ data, model }) => {
        if (model === 'DuckEggCollection') {
          this.duckEggCollectionIndexList.unshift(data)
          this.duckEggCollectionIndexGetCount++
          this.duckEggCollectionIndexList.pop()
        }
      }
      const dbUpdateEvent = ({ data, model }) => {
        if (model === 'DuckEggCollection') {
          this.duckEggCollectionIndexList = this.duckEggCollectionIndexList.map(item => {
            if (item.id === data.id) return Object.assign({ ...item, ...data })
            return Object.assign({ ...item })
          })
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', dbStoreEvent)
        .listen('DBUpdateEvent', dbUpdateEvent)
    },
    getDuckEggCollectionIndex () {
      const handler = duckEggCollectionIndexHandler
      const repository = duckEggCollectionRepository.index
      handler.setVue(this)
      handler.execute(repository, [this.tableParams])
    },
    async generateDr (id) {
      const confirm = await this.$confirm('are you sure you want to generate dr?')
      if (!confirm) return
      const handler = generateDRHandler
      const repository = duckEggCollectionRepository.generateDr
      handler.setVue(this)
      handler.execute(repository, [id])
    },
  },
}
</script>