import repository from './repository'

const resource = 'duck-egg-collections'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  generateDr (id) {
    return repository.post(`${resource}/${id}/generate-dr`)
  },
}
